@import '../../style/globals.scss';

.authNextStepButton {
    position: relative;
    display: inline-block;
    width: 481px;
    max-width: calc(100% - 70px);
    height: 45px;
    margin-right: 35px;
    margin-left: 35px;
}

.editProfilePage {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black; // Should match colour of bottom of bg image
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;

    &.loaded {
        background-position: center 0px;
    }

    &.step1 {
        transition: background-position 800ms ease-in-out 300ms, opacity ease-in-out 1000ms;
        background-position: center -100px;
    }

    &.step2 {
        transition: background-position 800ms ease-in-out, opacity ease-in-out 1000ms;
        background-position: center -200px;
    }

    &.page-transition-preVisible {
        opacity: 0;
    }

    &.page-transition-visible {
        opacity: 1;
    }

    &.page-transition-postVisible {
        opacity: 0;
    }

    &__content {
        margin-left: 70px;
        transition: opacity 1000ms;
        opacity: 1;
    }

    &__formContent {
        position: absolute;
        bottom: 0px;
        margin-bottom: 135px;
        width: 418px;

        label {
            display: block;
            font-size: $font-size-p;
            font-weight: 600;
        }
    }

    &__pickerContainer {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-top: $header-height + 40px;
        padding-bottom: 108px;
        inset: 0;

        @media (max-height: 1000px) {
            padding-bottom: 20px;
        }

        @media (max-height: 700px) {
            padding-top: 20px;
        }

        &Title {
            z-index: 10;
            text-align: center;
            font-family: var(--font-primary);
            font-size: $font-size-h3;
            margin: 0 8px;

            @media (max-width: $bp-mobile-chat) {
                font-size: $font-size-h4;
            }
        }
    }

    &__bottom {
        width: 100%;
        text-align: center;
    }

    &__errorMessage {
        margin-top: 5px;
        color: var(--token-arise-theme-1-state-state-error-light);
        text-align: center;

        &:empty::before {
            content: '';
            display: inline-block;
        }

        @media (max-width: $bp-s) {
            font-size: $font-size-p;
        }
    }

    .catastrophicError {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 10px;

        h2 {
            font-family: var(--font-primary);
            font-size: $font-size-h4;
            text-align: center;
        }
    }
}
