import { useEffect, useState } from 'react';

import * as api from '@/arise/api';
import { useTheme } from '@/theme';
import { isInsidersInstance } from '@/theme/vars';

// AuthGate makes sure everyone is logged in, and if they are not it logs them in as a guest
export default function AutoAuthGate({ children }: { children: React.ReactNode }) {
    // Do not run if autoGuestMode is disabled or on /auth routes
    const theme = useTheme();
    const autoGuestModeEnabled = theme.metadata?.authConfig?.autoGuestMode || isInsidersInstance; // Insiders cannot access logged out page
    const isOnAuthRoute = window.location.pathname.startsWith('/auth');
    const checkedDefault = isOnAuthRoute || !autoGuestModeEnabled;

    const [checked, setChecked] = useState(checkedDefault);

    useEffect(() => {
        (async () => {
            const authToken = api.getAuthToken();
            if (!checked && !authToken) {
                try {
                    await api.loginAsGuest();
                    setChecked(true);
                } catch (e) {
                    console.error(e);
                }
            } else {
                setChecked(true);
            }
        })();
    }, []);

    return checked ? <>{children}</> : null;
}
