// Each rule string should match each function, and the rule in the backend

export const EMAIL_RULE_STRING = 'Please enter a valid email address.';
export function isValidEmail(email: string): boolean {
    return email && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
}

export const PASSWORD_RULE_STRING = 'Your password must be at least 8 characters long.';
export function isValidPassword(password: string) {
    return password.length >= 8;
}

export const DISPLAY_NAME_RULE_STRING =
    'Your display name must be between 2 and 32 characters, start with a letter or number, and can only contain letters, numbers, underscores, and periods.';
export function isValidDisplayName(name: string) {
    const displayNameRegex = /^[a-zA-Z0-9][a-zA-Z0-9_.]{1,31}$/;
    return displayNameRegex.test(name);
}
