import { ThemeIds } from '@/theme/themes';
import { useEffect, useState } from 'react';

// Unused fonts are commented out
const fonts = {
    [ThemeIds.Titan]: [
        {
            family: 'Inter',
            source: `url('/assets/themes/titan/typography/inter/Inter-Regular.ttf') format('truetype')`,
            descriptors: {
                weight: '400',
                style: 'normal',
            },
        },
        {
            family: 'Inter',
            source: `url('/assets/themes/titan/typography/inter/Inter-SemiBold.ttf') format('truetype')`,
            descriptors: {
                weight: '500',
                style: 'normal',
            },
        },
        {
            family: 'Inter',
            source: `url('/assets/themes/titan/typography/inter/Inter-Bold.ttf') format('truetype')`,
            descriptors: {
                weight: 'bold',
                style: 'normal',
            },
        },
        {
            family: 'CheapSignage',
            source: `url('/assets/themes/titan/typography/cheapSignage/CheapSignage-Standard.ttf')`,
            descriptors: {
                weight: '400',
                style: 'normal',
            },
        },
        {
            family: 'Alexandria',
            source: `url('/assets/themes/titan/typography/alexandria/Alexandria-Regular.ttf') format('truetype')`,
            descriptors: {
                weight: '300',
                style: 'normal',
            },
        },
        {
            family: 'Alexandria',
            source: `url('/assets/themes/titan/typography/alexandria/Alexandria-Medium.ttf') format('truetype')`,
            descriptors: {
                weight: '400',
                style: 'normal',
            },
        },
        {
            family: 'Alexandria',
            source: `url('/assets/themes/titan/typography/alexandria/Alexandria-Bold.ttf') format('truetype')`,
            descriptors: {
                weight: 'bold',
                style: 'normal',
            },
        },
        // {
        //     family: 'Oswald',
        //     source: `url('/assets/themes/titan/typography/oswald/Oswald-Extralight.ttf') format('truetype')`,
        //     descriptors: {
        //         weight: '200',
        //         style: 'normal',
        //     },
        // },
        // {
        //     family: 'Oswald',
        //     source: `url('/assets/themes/titan/typography/oswald/Oswald-Light.ttf') format('truetype')`,
        //     descriptors: {
        //         weight: '300',
        //         style: 'normal',
        //     },
        // },
        // {
        //     family: 'Oswald',
        //     source: `url('/assets/themes/titan/typography/oswald/Oswald-Regular.ttf') format('truetype')`,
        //     descriptors: {
        //         weight: '400',
        //         style: 'normal',
        //     },
        // },
        {
            family: 'Oswald',
            source: `url('/assets/themes/titan/typography/oswald/Oswald-Medium.ttf') format('truetype')`,
            descriptors: {
                weight: '500',
                style: 'normal',
            },
        },
        {
            family: 'Oswald',
            source: `url('/assets/themes/titan/typography/oswald/Oswald-SemiBold.ttf') format('truetype')`,
            descriptors: {
                weight: '600',
                style: 'normal',
            },
        },
        // {
        //     family: 'Oswald',
        //     source: `url('/assets/themes/titan/typography/oswald/Oswald-Bold.ttf') format('truetype')`,
        //     descriptors: {
        //         weight: '700',
        //         style: 'normal',
        //     },
        // },
    ],
    [ThemeIds.Phobos]: [
        {
            family: 'ChakraPetchTitle',
            source: `url('/assets/themes/phobos/typography/chakraPetch/ChakraPetch-Bold.ttf') format('truetype')`,
            descriptors: {
                weight: 'bold',
                style: 'normal',
            },
        },
        {
            family: 'ChakraPetch',
            source: `url('/assets/themes/phobos/typography/chakraPetch/ChakraPetch-Light.ttf') format('truetype')`,
            descriptors: {
                weight: '300',
                style: 'normal',
            },
        },
        {
            family: 'ChakraPetch',
            source: `url('/assets/themes/phobos/typography/chakraPetch/ChakraPetch-Regular.ttf') format('truetype')`,
            descriptors: {
                weight: 'normal',
                style: 'normal',
            },
        },
        {
            family: 'ChakraPetch',
            source: `url('/assets/themes/phobos/typography/chakraPetch/ChakraPetch-Bold.ttf') format('truetype')`,
            descriptors: {
                weight: '500',
                style: 'normal',
            },
        },
    ],
    [ThemeIds.Hyperion]: [
        {
            family: 'ChakraPetchTitle',
            source: `url('/assets/themes/hyperion/typography/chakraPetch/ChakraPetch-Bold.ttf') format('truetype')`,
            descriptors: {
                weight: 'bold',
                style: 'normal',
            },
        },
        {
            family: 'ChakraPetch',
            source: `url('/assets/themes/hyperion/typography/chakraPetch/ChakraPetch-Light.ttf') format('truetype')`,
            descriptors: {
                weight: '300',
                style: 'normal',
            },
        },
        {
            family: 'ChakraPetch',
            source: `url('/assets/themes/hyperion/typography/chakraPetch/ChakraPetch-Regular.ttf') format('truetype')`,
            descriptors: {
                weight: 'normal',
                style: 'normal',
            },
        },
        {
            family: 'ChakraPetch',
            source: `url('/assets/themes/hyperion/typography/chakraPetch/ChakraPetch-Bold.ttf') format('truetype')`,
            descriptors: {
                weight: '500',
                style: 'normal',
            },
        },
        {
            family: 'HomePlanetBB',
            source: `url('/assets/themes/hyperion/typography/HomePlanetBB/HomePlanetBB-Bold.otf') format('opentype')`,
            descriptors: {
                weight: '500',
                style: 'normal',
            },
        },
    ],
    [ThemeIds.Mimas]: [
        {
            family: 'ChakraPetchTitle',
            source: `url('/assets/themes/mimas/typography/chakraPetch/ChakraPetch-Bold.ttf') format('truetype')`,
            descriptors: {
                weight: 'bold',
                style: 'normal',
            },
        },
        {
            family: 'ChakraPetch',
            source: `url('/assets/themes/mimas/typography/chakraPetch/ChakraPetch-Light.ttf') format('truetype')`,
            descriptors: {
                weight: '300',
                style: 'normal',
            },
        },
        {
            family: 'ChakraPetch',
            source: `url('/assets/themes/mimas/typography/chakraPetch/ChakraPetch-Regular.ttf') format('truetype')`,
            descriptors: {
                weight: 'normal',
                style: 'normal',
            },
        },
        {
            family: 'ChakraPetch',
            source: `url('/assets/themes/mimas/typography/chakraPetch/ChakraPetch-Bold.ttf') format('truetype')`,
            descriptors: {
                weight: '500',
                style: 'normal',
            },
        },
    ],
};

const globalFonts = [
    {
        family: 'Inter',
        source: `url('/assets/global/typography/inter/Inter-Regular.ttf') format('truetype')`,
        descriptors: {
            weight: '400',
            style: 'normal',
        },
    },
    {
        family: 'Inter',
        source: `url('/assets/global/typography/inter/Inter-SemiBold.ttf') format('truetype')`,
        descriptors: {
            weight: '500',
            style: 'normal',
        },
    },
    {
        family: 'Inter',
        source: `url('/assets/global/typography/inter/Inter-Bold.ttf') format('truetype')`,
        descriptors: {
            weight: 'bold',
            style: 'normal',
        },
    },
];

export default function useFontLoader() {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const fontlist = [...(fonts[process.env.PUBLIC_INSTANCE] || []), ...globalFonts];
        if (!fontlist) {
            console.error('No fonts found for instance');
            setReady(true);
            return;
        }
        const fontPromises = fontlist.map((font) => {
            return new Promise<void>((resolve) => {
                const newFont = new FontFace(font.family, font.source, font.descriptors);
                newFont
                    .load()
                    .then((loadedFont) => {
                        document.fonts.add(loadedFont);
                        resolve();
                    })
                    .catch((error) => {
                        console.error('Failed to load font', error);
                        resolve();
                    });
            });
        });

        Promise.all(fontPromises).then(() => {
            setReady(true);
        });
    }, []);

    return {
        ready,
    };
}
