import { ThemeStylesProvider } from '../theme';
import { Provider } from '@/state/store';
import { AudioProvider } from '@/theme/audio';
import { GlobalPusherSubscribeProvider } from '@/arise/global/pusher';
import AnalyticsProvider from '@/arise/global/analytics';
import Preloader from '@/components/Preloader';
import PostPurchaseScreen from '@/components/ShopDrawer/PostPurchaseScreen';
import AutoAuthGate from '@/app/auth/AutoAuthGate';
import AriseSignupModal from '@/components/AriseAuth';
import RedeemPrizeModal from '@/components/RedeemPrizeModal';

import './globals.scss';

export default function RootLayout({ children }: { children: React.ReactNode }) {
    return (
        <Provider>
            <Preloader>
                <AutoAuthGate>
                    <AnalyticsProvider />
                    <GlobalPusherSubscribeProvider />
                    <AudioProvider />
                    <ThemeStylesProvider />
                    {children}
                    <RedeemPrizeModal />
                    <PostPurchaseScreen />
                    <AriseSignupModal />
                </AutoAuthGate>
            </Preloader>
        </Provider>
    );
}
