import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as arise from '@/arise/api';
import Input from '@/components/Forms/Input';
import Modal from '@/components/Modal';
import { sendEvent } from '@/utils/analytics';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { SUPPORT_EMAIL, homePath, isInsidersInstance } from '@/theme/vars';
import { useInitialAuthCheck } from '@/hooks/auth';
import { EditProfileErrorCode, User } from '@/models/auth';
import { updateUser } from '@/state/features/auth';
import { useCurrentUserIsGuest } from '@/hooks/users';
import { DISPLAY_NAME_RULE_STRING, isValidDisplayName } from '@/utils/validation';

import { AuthButton } from '../../components/LoginButton';
import * as styles from '../../styles.module.scss';

export default function SetDisplayNameModal() {
    const [displayName, setDisplayName] = useState('');
    const [errorCode, setErrorCode] = useState<EditProfileErrorCode>();
    const [isSendingData, setIsSendingData] = useState(false);

    useInitialAuthCheck();
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const isGuest = useCurrentUserIsGuest();
    useEffect(() => {
        if (isGuest || isInsidersInstance) navigate(homePath);
    }, [isGuest, isInsidersInstance]);

    useEffect(() => {
        const DEBOUNCE_DELAY = 500;
        // Check display name validity locally once user stops typing for DEBOUNCE_DELAY
        const handler = setTimeout(() => {
            if (!displayName || isValidDisplayName(displayName)) {
                if (errorCode === 'DISPLAYNAME_INVALID') {
                    setErrorCode(null);
                }
            } else {
                setErrorCode('DISPLAYNAME_INVALID');
            }
        }, DEBOUNCE_DELAY);
        return () => clearTimeout(handler);
    }, [displayName]);

    async function onSubmit() {
        if (!isValidDisplayName(displayName)) {
            setErrorCode('DISPLAYNAME_INVALID');
            return;
        }
        if (isSendingData) return;
        setIsSendingData(true);
        try {
            const body = {
                displayName: displayName,
            };
            const updatedUser: User = await arise.editProfile(currentUserProfile.id, body);

            sendEvent('set_display_name_fullscreen', displayName);
            dispatch(updateUser(updatedUser));
            setTimeout(() => navigate(homePath), 500);
        } catch (error) {
            setErrorCode(error.message ?? 'UNKNOWN_ERROR');
            console.error('Could not update display name', error);
            setIsSendingData(false);
        }
    }

    return (
        <Modal
            canScroll
            isVisible={true}
            modalClassname={styles.AriseLoginModal}
            shouldCloseOnExternalClick={false}
            zIndex={1000}
            hasCloseButton={false}
        >
            <img className={styles.logo} alt="Those Beyond Logo" src="/assets/global/those-beyond-icon.svg"></img>
            <h2>Choose your display name</h2>
            <label htmlFor="arise-set-display-name">Display Name</label>
            <Input
                className={styles.input}
                id="arise-set-display-name"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
            />
            <p>This is how others see you in the chat.</p>
            <br />
            <AuthButton
                disabled={!displayName || isSendingData || !currentUserProfile || !isValidDisplayName(displayName)}
                text="Continue"
                onClick={onSubmit}
                style={{ marginTop: 20 }}
            />
            <p className={styles.errorMessage}>{getEditProfileErrorMessage(errorCode)}</p>
        </Modal>
    );
}

function getEditProfileErrorMessage(errorCode: EditProfileErrorCode): string {
    if (!errorCode) return '';
    switch (errorCode) {
        case 'DISPLAYNAME_INVALID':
            return DISPLAY_NAME_RULE_STRING;
        case 'DISPLAYNAME_VIOLATES_POLICY':
            return 'Your display name contains inappropriate content.';
        default:
            return `Could not set your display name, please try again or contact ${SUPPORT_EMAIL}`;
    }
}
