import { useTheme } from '@/theme';

import { AuthButton, ExternalLoginButtons } from '../../components/LoginButton';
import { ButtonLink } from '../../components/ButtonLink';
import { AriseAuthScreenProps } from '../..';

import * as styles from '../../styles.module.scss';

export default function SignupLanding({ navigateToScreen }: AriseAuthScreenProps) {
    const theme = useTheme();
    const authConfig = theme.metadata?.authConfig;
    const termsUrl = theme.customJSON?.auth?.links?.terms;
    const privacyUrl = theme.customJSON?.auth?.links?.privacy;

    return (
        <div>
            <h2>Join Those Beyond</h2>
            <p>A place where fans can connect, explore, and participate together in the worlds they love.</p>
            <br />
            <ExternalLoginButtons authProviders={authConfig?.authProviders} />
            {!!authConfig?.authProviders?.length && authConfig?.enableEmailAccounts && (
                <div className={styles.separator}>or</div>
            )}
            {authConfig?.enableEmailAccounts && (
                <AuthButton text="Continue with email" onClick={() => navigateToScreen('emailSignup')} />
            )}
            <br />
            <p className={styles.smallText}>
                By signing up, you agree to the{' '}
                <a href={termsUrl} target="_blank" rel="noopener noreferrer">
                    Terms of Service
                </a>{' '}
                and{' '}
                <a target="_blank" rel="noopener noreferrer" href={privacyUrl}>
                    Privacy Policy
                </a>
                .
            </p>
            <br />
            <span>
                Have an account?{' '}
                <ButtonLink isHighlighted onClick={() => navigateToScreen('login')}>
                    Log in
                </ButtonLink>
            </span>
        </div>
    );
}
