@import '../../style/globals.scss';

.ShopDrawer {
    position: absolute;
    z-index: 120;
    top: 0;
    right: -$sidebar-width;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: var(--token-accent-theme-1-text-text-default);
    // Needed for safari
    -webkit-backdrop-filter: blur(10px);
    transition: $sidebar-transition, opacity 0s linear 0.5s;
    opacity: 0;

    @media (max-width: $bp-mobile-chat) {
        right: -100%;
        width: 100%;
    }

    &.isOpen {
        right: 0;
        transition: $sidebar-transition, opacity 0s linear 0s;
        opacity: 1;
    }

    .scrollContainer {
        @include scrollbar-styles;
        position: relative;
        overflow-y: scroll;
        width: 100%;
        height: 100%;
    }

    h2 {
        font-family: var(--font-primary);
        font-size: $font-size-h6;
        text-align: center;
    }

    .longTitle {
        font-size: $font-size-p;
    }

    .content {
        @include border-image();
        position: absolute;
        top: 70px;
        left: 50%;
        width: 100%;
        max-width: 430px;
        min-height: 500px;
        padding: 20px;
        transform: translateX(-50%);

        &.noBackgroundImage {
            background-color: var(--token-accent-theme-1-interface-mono-interface-midground);
            border-radius: 10px;
        }

        @media (max-width: $bp-mobile-chat) {
            max-width: 500px;
        }

        .title {
            margin: 10px;
        }

        .emptyText {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%);
        }

        li {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .items {
        position: relative;
        width: 100%;
        min-height: 500px;
    }

    .tabButtons {
        justify-content: flex-start;
        width: 100%;
        display: flex;
        gap: 20px;

        li {
            width: 40px;
            height: 40px;
        }

        button {
            width: 100%;
            height: 100%;

            svg {
                width: 100%;
                height: 100%;
            }

            svg path {
                fill: var(--token-accent-theme-1-button-button-secondary-inactive);
            }

            &.isActive {
                svg path {
                    fill: var(--token-accent-theme-1-button-button-secondary-active);
                }
            }

            &:hover,
            &:focus {
                svg path {
                    fill: var(--token-accent-theme-1-button-button-secondary-highlight);
                }
            }
        }
    }

    .assets {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        gap: 20px;
        width: 100%;
    }

    .contentTitle {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
    }

    .topBar {
        position: absolute;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80px;

        .title {
            width: calc(100% - 130px);
            text-align: center;
        }

        .closeButton {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}
