import { Theme } from '@/models/instance';
import { loadInstance, setInstanceLoadTimestamp } from '@/state/features/app';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { useTheme } from '@/theme';
import { useEffect, useState, useRef } from 'react';

export default function useThemeLoader() {
    const [themeLoaded, setThemeLoaded] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [imageProgress, setImageProgress] = useState({ loaded: 0, total: 0 });
    const [error, setError] = useState(false);
    const theme = useTheme();
    const hasInitiatedLoad = useRef(false);

    const dispatch = useAppDispatch();
    const isInstanceLoading = useAppSelector((state) => state.app.isInstanceLoading);
    const instanceLoaded = useAppSelector((state) => state.app.instanceLoaded);

    async function loadImages(theme: Theme) {
        const images = [
            theme.files.logo,
            theme.files.splash_bg,
            theme.files.inventory_backdrop,
            theme.files.instance_mark,
        ];

        setImageProgress({ loaded: 0, total: images.length });

        await Promise.all(
            images.map(async (url) => {
                try {
                    await loadImage(url);
                } catch (error) {
                    setError(true);
                }
                setImageProgress((prev) => ({ loaded: prev.loaded + 1, total: prev.total }));
            }),
        );
        setImagesLoaded(true);
    }

    useEffect(() => {
        // Only initiate loading if it hasn't been started yet and isn't already in progress
        if (!hasInitiatedLoad.current && !isInstanceLoading && !instanceLoaded) {
            hasInitiatedLoad.current = true;
            const universeId = process.env.PUBLIC_INSTANCE_ID;
            dispatch(setInstanceLoadTimestamp()); // Update timestamp before loading
            dispatch(loadInstance(universeId));
        }
    }, [dispatch, isInstanceLoading, instanceLoaded]);

    useEffect(() => {
        if (theme.loaded) {
            loadImages(theme).then(() => {
                setThemeLoaded(true);
            });
        }
    }, [theme]);

    return {
        themeLoaded,
        imagesLoaded,
        imageProgress,
        ready: themeLoaded && imagesLoaded,
        error,
    };
}

async function loadImage(url: string) {
    return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve();
        };
        img.onerror = () => {
            console.error('Failed to load image', url);
            reject();
        };
        img.src = url;
    });
}
