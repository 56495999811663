import { useEffect, useState } from 'react';

import { SUPPORT_EMAIL } from '@/theme/vars';
import { passwordChange } from '@/arise/api';
import { EMAIL_RULE_STRING, PASSWORD_RULE_STRING, isValidEmail, isValidPassword } from '@/utils/validation';
import Input from '@/components/Forms/Input';
import Modal from '@/components/Modal';
import { PasswordResetErrorCode } from '@/models/auth';

import { AuthButton } from '../../components/LoginButton';
import * as styles from '../../styles.module.scss';

export default function PasswordReset() {
    const [errorCode, setErrorCode] = useState<PasswordResetErrorCode>();
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const [uiStatus, setUiStatus] = useState<'enterDetails' | 'passwordChanged'>('enterDetails');

    async function onSubmit() {
        if (!isValidEmail(email)) {
            setErrorCode('EMAIL_INVALID');
            return;
        }
        if (password !== passwordConfirmation) {
            setErrorCode('PASSWORD_MISMATCH');
            return;
        }
        if (!isValidPassword(password)) {
            setErrorCode('PASSWORD_TOO_SHORT');
            return;
        }
        try {
            await passwordChange(email, token, passwordConfirmation);
            setUiStatus('passwordChanged');
        } catch (error) {
            setErrorCode(error.message ?? 'UNKNOWN_ERROR');
            console.error('Could not reset password', error);
        }
    }

    useEffect(() => {
        const query = window.location.search;
        const urlToken = new URLSearchParams(query).get('token');
        if (!urlToken) {
            console.warn('No password reset url token found');
            window.location.href = '/';
        }
        setToken(urlToken);
    }, []);

    const EnterDetails = (
        <div>
            <h2>Reset your password</h2>
            <p>Please enter your new password</p>
            <br />
            <label htmlFor="arise-password-reset-email">Email</label>
            <Input
                className={styles.input}
                id="arise-password-reset-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                autoComplete="email"
            />
            <label htmlFor="arise-password-reset-password">New Password</label>
            <Input
                className={styles.input}
                id="arise-password-reset-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                type="password"
                autoComplete="new-password"
            />
            <label htmlFor="arise-password-reset-password-confirmation">Confirm Password</label>
            <Input
                className={styles.input}
                id="arise-password-reset-password-confirmation"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                placeholder="Password"
                type="password"
                autoComplete="new-password"
            />
            <AuthButton
                disabled={!(token && email && password && passwordConfirmation)}
                text="Change Password"
                onClick={onSubmit}
            />
            <p className={styles.errorMessage}>{getPasswordResetErrorMessage(errorCode)}</p>
        </div>
    );

    const PasswordChanged = (
        <div>
            <h2>Password Changed</h2>
            <p>Your password has been changed successfully!</p>
            <br />
            <AuthButton text="Back to Home" onClick={() => (window.location.href = '/')} />
        </div>
    );
    return (
        <Modal
            canScroll
            isVisible
            onClose={() => {
                window.location.href = '/';
            }}
            modalClassname={styles.AriseLoginModal}
            closeButtonClassname={styles.closeButton}
            closeButtonStyle="icon"
            shouldCloseOnExternalClick={false}
            zIndex={1000}
            leaveSpaceAboveAndBelow={false}
        >
            <img className={styles.logo} alt="Those Beyond Logo" src="/assets/global/those-beyond-icon.svg"></img>
            {uiStatus === 'enterDetails' ? EnterDetails : PasswordChanged}
        </Modal>
    );
}

function getPasswordResetErrorMessage(errorCode: PasswordResetErrorCode): string {
    if (!errorCode) return '';
    switch (errorCode) {
        case 'EMAIL_INVALID':
            return EMAIL_RULE_STRING;
        case 'INVALID_RESET_TOKEN':
            return 'Could not reset password, the link may be expired. Please try again.';
        case 'PASSWORD_MISMATCH':
            return 'The passwords do not match.';
        case 'PASSWORD_TOO_SHORT':
            return PASSWORD_RULE_STRING;
        default:
            return `Could not reset password. Check your details are correct, or contact ${SUPPORT_EMAIL}`;
    }
}
