import { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { sendEvent } from '@/utils/analytics';
import { AuthDrawer, authDrawerWidth } from '@/components/AuthDrawer';
import DisplayNameWithBadges from '@/components/DisplayNameWithBadges';
import Button from '@/components/Forms/Button';
import IconButton from '@/components/IconButton';
import MarkdownRenderer from '@/components/MarkdownRenderer';
import { useTheme } from '@/theme';
import { logout } from '@/state/features/auth';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { useAudio } from '@/hooks/audio';
import { useCurrentUserIsGuest } from '@/hooks/users';
import { setShowingSettingsMenu } from '@/state/features/app';

import * as styles from './styles.module.scss';

interface SettingsProps {
    visible: boolean;
    displayNameShouldLinkToProfile?: boolean;
}

// Only found in customJSON
interface SettingsLogo {
    imageUrl: string;
    alt: string;
    href?: string;
}

export default function Settings({ visible, displayNameShouldLinkToProfile }: SettingsProps) {
    const theme = useTheme();
    const audio = useAudio();
    const settingsContentRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);
    const isGuest = useCurrentUserIsGuest();

    // Close the setting menu when the user presses the escape key
    useEffect(() => {
        function keyUpHandler(e: KeyboardEvent) {
            if (e.key === 'Escape') {
                dispatch(setShowingSettingsMenu(false));
            }
        }

        if (visible) {
            window.addEventListener('keyup', keyUpHandler);
            settingsContentRef.current.focus();
        } else {
            window.removeEventListener('keyup', keyUpHandler);
        }

        return () => {
            window.removeEventListener('keyup', keyUpHandler);
        };
    }, [visible]);

    // Hide the settings menu when the user clicks outside of it
    function clickHandler(e: any) {
        if (settingsContentRef.current && !settingsContentRef.current.contains(e.target as Node)) {
            dispatch(setShowingSettingsMenu(false));
        }
    }

    const Logo = ({ logo }: { logo: SettingsLogo }) => {
        if (!logo.imageUrl) return null;
        const image = <img src={logo.imageUrl} alt={logo.alt} />;

        if (logo.href) {
            return (
                <a href={logo.href} className={styles.logoContainer} target="_blank" rel="noreferrer">
                    {image}
                </a>
            );
        }
        return <div className={styles.logoContainer}>{image}</div>;
    };

    return (
        <>
            <div
                className={classNames(styles.backdropBlur, {
                    [styles.visible]: visible,
                })}
            ></div>
            <div
                className={classNames(styles.Settings, {
                    [styles.visible]: visible,
                })}
                onClick={clickHandler}
                aria-hidden={!visible}
                inert={visible ? undefined : ''}
            >
                <div className="absolute top-0 left-0 w-full h-full"></div>
                <AuthDrawer
                    imagePath={theme.customJSON.settings?.backgroundImage}
                    style={{
                        minWidth: 520,
                    }}
                ></AuthDrawer>
                <IconButton
                    iconType="close"
                    ariaLabel="Close Settings"
                    className={classNames(styles.closeButton)}
                    buttonStyle="round"
                    onClick={() => {
                        dispatch(setShowingSettingsMenu(false));
                    }}
                />
                <div className={styles.content} ref={settingsContentRef} tabIndex={visible ? 0 : -1}>
                    <div className={styles.top}>
                        <DisplayNameWithBadges
                            profile={currentUserProfile}
                            shouldLinkToProfile={displayNameShouldLinkToProfile}
                        />
                    </div>
                    <div className={styles.middle}>
                        {theme.metadata?.settingsMarkdown && (
                            <MarkdownRenderer
                                base64MarkdownContent={theme.metadata?.settingsMarkdown}
                                textAlign="left"
                            />
                        )}
                    </div>
                    <div className={styles.bottom}>
                        {!isGuest && (
                            <Button
                                className={styles.logoutButton}
                                disabled={!visible}
                                onClick={() => {
                                    dispatch(logout());
                                    sendEvent('logout_user');
                                }}
                            >
                                Log Out
                            </Button>
                        )}
                        <div className={styles.logos}>
                            {Array.isArray(theme.customJSON?.settings?.logos) &&
                                theme.customJSON?.settings?.logos.map((logo: SettingsLogo, index: number) => (
                                    <Logo key={index} logo={logo} />
                                ))}
                        </div>
                        {theme.customJSON?.settings?.footerText && (
                            <p className={styles.footerText}>{theme.customJSON?.settings?.footerText} </p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
