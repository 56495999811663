import * as styles from './styles.module.scss';
import classNames from 'classnames';

interface AuthDrawerProps extends React.HTMLAttributes<HTMLDivElement> {
    imagePath?: string;
    children?: React.ReactNode;
}

export function AuthDrawer({ imagePath, children, ...props }: AuthDrawerProps) {
    const classes = classNames({
        [styles.AuthDrawer]: true,
        [styles['AuthDrawer--noImage']]: !imagePath,
    });

    const drawerRightStyles = imagePath ? { backgroundImage: `url(${imagePath})` } : {};

    return (
        <div className={classes} {...props}>
            <div className={styles.AuthDrawer__drawer}>
                <div className={styles.AuthDrawer__drawerLeft}></div>
                <div className={styles.AuthDrawer__drawerRight} style={drawerRightStyles}></div>
                {children}
            </div>
        </div>
    );
}
