import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { initialAuthCheck } from '@/state/features/auth';
import { loadCurrentUserProfile } from '@/state/features/profile';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { profileHasOnboarded } from '@/utils/profile';
import { useCurrentUserIsGuest } from '@/hooks/users';
import { getInsidersLoginUrl, getInsidersRegisterUrl, isInsidersInstance } from '@/theme/vars';
import { setAuthModalScreen } from '@/state/features/app';

const loginpath = '/auth/landing';

export function useAuthRedirect() {
    const auth = useAppSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth.loggedIn) {
            navigate(loginpath);
        }
    }, []);
}

export function useInitialAuthCheck({
    loggedInRedirect,
    loggedOutRedirect = loginpath,
}: {
    loggedInRedirect?: string;
    loggedOutRedirect?: string;
} = {}) {
    const { intialAuthCheckComplete, isInitialAuthCheckInProgress, loggedIn, user, hasLoggedOut } = useAppSelector(
        (state) => state.auth,
    );
    const { currentUserProfileCheckComplete, currentUserProfileCheckInProgress, currentUserProfile } = useAppSelector(
        (state) => state.profile,
    );
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isGuest = useCurrentUserIsGuest();

    useEffect(() => {
        if (intialAuthCheckComplete || isInitialAuthCheckInProgress) return;
        dispatch(initialAuthCheck());
    }, []);

    useEffect(() => {
        if (!user || currentUserProfileCheckComplete || currentUserProfileCheckInProgress) return;
        dispatch(loadCurrentUserProfile([user.id]));
    }, [user]);

    useEffect(() => {
        if (!intialAuthCheckComplete) return;
        if (loggedIn) {
            if (!currentUserProfileCheckComplete) return;

            // Send to either edit profile page, edit display name page, or just log them in depending on their data
            if (isGuest) {
                if (loggedInRedirect) navigate(loggedInRedirect);
                return;
            }
            if (profileHasOnboarded(currentUserProfile)) {
                if (user.hasSetUsername) {
                    if (loggedInRedirect) navigate(loggedInRedirect);
                    return;
                } else {
                    navigate('/edit-display-name');
                    return;
                }
            } else {
                navigate('/edit-profile');
                return;
            }
        } else {
            if (hasLoggedOut && isInsidersInstance && process.env.PUBLIC_INSIDERS_DOMAIN && !isGuest) {
                // Redirect to Insiders logout page as we currently have no other way of logging user out of Insiders
                window.location.href = process.env.PUBLIC_INSIDERS_DOMAIN + '/logout';
            } else if (loggedOutRedirect) {
                navigate(loggedOutRedirect);
            }
        }
    }, [intialAuthCheckComplete, loggedIn, currentUserProfileCheckComplete, currentUserProfile]);

    return {
        isInProgress: isInitialAuthCheckInProgress,
    };
}

export function useUser() {
    const user = useAppSelector((state) => state.auth.user);
    return user;
}

export function useNavigateToSignup() {
    const dispatch = useAppDispatch();
    return () => {
        if (isInsidersInstance) {
            window.location.href = getInsidersRegisterUrl();
        } else {
            dispatch(setAuthModalScreen('signupLanding'));
        }
    };
}

export function useNavigateToLogin() {
    const dispatch = useAppDispatch();
    return () => {
        if (isInsidersInstance) {
            window.location.href = getInsidersLoginUrl();
        } else {
            dispatch(setAuthModalScreen('login'));
        }
    };
}
