import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import EditProfilePage from '@/app/edit-profile/page';
import EditProfileLayout from '@/app/edit-profile/layout';
import SetDisplayNamePage from '@/app/edit-display-name/page';
import RootLayout from '@/app/layout';
import LocationPage from '@/app/location/[id]/page';
import LocationLayout from '@/app/location/layout';
import SceneSelectorLayout from '@/app/selector/layout';
import SceneSelectorPage from '@/app/selector/page';
import OAuthExchangePage from '@/app/auth/oauth/exchange';
import PasswordResetPage from '@/app/auth/passwordReset/page';
import LandingPage from '@/app/auth/landing';
import HomePage from '@/app/page';
import InsidersLogin from '@/app/auth/insiders/page';
import { initSentry } from './utils/analytics/sentry';
import { isInsidersInstance } from './theme/vars';
import { Provider } from './state/store';
import testJson from '../public/json/test.json';

// Log the imported JSON
console.log('Test JSON:', testJson);

// e.g. not insiders
const authRoutesDefault = [
    {
        path: 'landing',
        element: (
            <RootLayout>
                <Provider>
                    <LandingPage />
                </Provider>
            </RootLayout>
        ),
    },
    {
        path: 'reset-password',
        element: (
            <RootLayout>
                <Provider>
                    <PasswordResetPage />
                </Provider>
            </RootLayout>
        ),
    },
];

const getAuthRoutesInsiders = () => {
    // Prev used <InsidersExternalRedirect />
    const authRoutes = [
        {
            path: 'insiders',
            element: (
                <RootLayout>
                    <InsidersLogin />
                </RootLayout>
            ),
        },
    ];
    return authRoutes;
};

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <RootLayout>
                <HomePage />
            </RootLayout>
        ),
    },
    {
        path: '/auth',
        children: isInsidersInstance ? getAuthRoutesInsiders() : authRoutesDefault,
    },
    {
        path: '/edit-profile',
        element: (
            <RootLayout>
                <EditProfileLayout>
                    <EditProfilePage />
                </EditProfileLayout>
            </RootLayout>
        ),
    },
    {
        path: '/edit-display-name',
        element: (
            <RootLayout>
                <Provider>
                    <SetDisplayNamePage />
                </Provider>
            </RootLayout>
        ),
    },
    {
        path: '/oauth/exchange',
        element: (
            <Provider>
                <OAuthExchangePage />
            </Provider>
        ),
    },
    // {
    //     path: '/map',
    //     element: (
    //         <RootLayout>
    //             <MapLayout>
    //                 <MapBasicPage />
    //             </MapLayout>
    //         </RootLayout>
    //     ),
    // },
    ...(process.env.PUBLIC_DISABLE_SELECTOR !== 'true'
        ? [
              {
                  path: '/selector',
                  element: (
                      <RootLayout>
                          <SceneSelectorLayout>
                              <SceneSelectorPage />
                          </SceneSelectorLayout>
                      </RootLayout>
                  ),
              },
          ]
        : []),
    {
        path: '/location/:locationId',
        element: (
            <RootLayout>
                <LocationLayout>
                    <LocationPage />
                </LocationLayout>
            </RootLayout>
        ),
    },
    {
        path: '*',
        element: <Navigate to="/" />, // 404
    },
]);

initSentry();

const root = document.createElement('div');
document.body.appendChild(root);

ReactDOM.createRoot(root).render(<RouterProvider router={router} />);
