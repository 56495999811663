import { useRef, TransitionEvent } from 'react';
import classNames from 'classnames';

import { useTheme } from '@/theme';
import IconButton, { IconButtonStyleTypes } from '@/components/IconButton';
import LoadingScreen from '@/components/LoadingScreen';

import * as styles from './styles.module.scss';

interface ModalPropsBase {
    title?: string;
    isVisible: boolean;
    modalClassname?: string;
    contentClassname?: string;
    closeButtonClassname?: string;
    classname?: string;
    children?: React.ReactNode;
    canScroll?: boolean;
    isLoading?: boolean;
    zIndex?: number | 'auto';
    leaveSpaceAboveAndBelow?: boolean; // If space should be left above and below modal (to keep floating menu in view)
    backgroundImageOverride?: string;
    closeButtonStyle?: IconButtonStyleTypes;
    shouldCloseOnExternalClick?: boolean; // If clicking outside the modal should close it
    hasCloseButton?: boolean;
}

// onClose only required if hasCloseButton = true
type ModalProps = ModalPropsBase &
    ({ hasCloseButton: true; onClose: () => void } | { hasCloseButton?: false; onClose?: () => void });

export default function Modal({
    title,
    onClose,
    isVisible,
    modalClassname,
    contentClassname,
    closeButtonClassname,
    classname,
    canScroll,
    isLoading,
    children,
    zIndex = 'auto',
    leaveSpaceAboveAndBelow = true,
    backgroundImageOverride,
    closeButtonStyle = 'round',
    shouldCloseOnExternalClick = true,
    hasCloseButton = true,
}: ModalProps) {
    const theme = useTheme();
    const backgroundImage = backgroundImageOverride ?? theme.customJSON.modalBackgroundImage;
    const containerRef = useRef<HTMLDivElement>(null);
    const modalContentRef = useRef<HTMLDivElement>(null);
    const closeButtonRef = useRef<HTMLButtonElement>(null);

    function close() {
        if (onClose) {
            onClose();
        }
    }

    // Close modal when user presses escape
    function onKeyUp(e: any) {
        if (e.key === 'Escape') {
            close();
        }
    }

    // Hide modal when the user clicks outside of it
    function clickHandler(e: any) {
        if (
            shouldCloseOnExternalClick &&
            modalContentRef.current &&
            !modalContentRef.current.contains(e.target as Node)
        ) {
            close();
        }
    }

    // Move focus to modal when opened
    function onTransitionEnd(e: TransitionEvent<HTMLDivElement>) {
        if (isVisible && e.target === e.currentTarget) {
            setTimeout(() => {
                closeButtonRef.current?.focus();
            }, 200);
        }
    }

    return (
        <div
            onClick={clickHandler}
            onKeyUp={onKeyUp}
            inert={isVisible ? undefined : ''}
            className={classNames(classname, 'overflow-hidden')}
            ref={containerRef}
            onScroll={() => {
                // Stop scroll jumping to items which become focussed off screen
                containerRef.current?.scrollTo(0, 0);
            }}
        >
            <div
                className={classNames('absolute top-0 left-0 w-full h-full', styles.blur, {
                    [styles['visible']]: isVisible,
                })}
                style={{
                    transition: 'backdrop-filter 300ms, visibility 300ms',
                    visibility: isVisible ? 'visible' : 'hidden',
                    backdropFilter: isVisible ? 'blur(10px)' : 'blur(0px)',
                    zIndex,
                }}
            ></div>
            <div
                onTransitionEnd={onTransitionEnd}
                aria-modal={isVisible}
                aria-hidden={!isVisible}
                {...(title && { 'aria-labelledby': 'modalTitle' })}
                className={classNames(styles.modalDrawer, {
                    [styles['visible']]: isVisible,
                })}
                style={{ zIndex }}
            >
                <div
                    onClick={clickHandler}
                    ref={modalContentRef}
                    className={classNames(styles.Modal, modalClassname, {
                        [styles.noBackgroundImage]: !backgroundImage,
                        [styles.canScroll]: canScroll,
                        [styles['leaveSpaceAboveAndBelow']]: leaveSpaceAboveAndBelow,
                    })}
                    style={backgroundImage ? { backgroundImage: `url("${backgroundImage}")` } : {}}
                >
                    {hasCloseButton && (
                        <IconButton
                            iconType="close"
                            buttonStyle={closeButtonStyle}
                            className={classNames(styles.closeButton, closeButtonClassname, {
                                [styles.round]: closeButtonStyle === 'round',
                                [styles.icon]: closeButtonStyle === 'icon',
                            })}
                            ariaLabel="Close modal"
                            onClick={close}
                            disabled={!isVisible}
                            buttonRef={closeButtonRef}
                        />
                    )}
                    <div className={classNames(styles.contentContainer, contentClassname)}>
                        {title && (
                            <h2 id="modalTitle" className={styles.modalTitle}>
                                {title}
                            </h2>
                        )}
                        {children}
                    </div>
                </div>
                <LoadingScreen show={isLoading} hasBackground={false} />
            </div>
        </div>
    );
}
