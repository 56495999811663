import { useState } from 'react';

import { passwordReset } from '@/arise/api';
import Input from '@/components/Forms/Input';
import { EMAIL_RULE_STRING, isValidEmail } from '@/utils/validation';
import { SUPPORT_EMAIL } from '@/theme/vars';
import { useTheme } from '@/theme';
import { ForgottenPasswordErrorCode } from '@/models/auth';

import { AriseAuthScreenProps } from '../..';
import { AuthButton } from '../../components/LoginButton';
import * as styles from '../../styles.module.scss';

export default function ForgottenPassword({ navigateToScreen }: AriseAuthScreenProps) {
    const [email, setEmail] = useState('');
    const [errorCode, setErrorCode] = useState<ForgottenPasswordErrorCode>();

    const theme = useTheme();
    const helpUrl = theme.customJSON?.auth?.links?.help;

    const [uiStatus, setUiStatus] = useState<'enterEmail' | 'emailSent'>('enterEmail');

    async function onSubmit() {
        if (!isValidEmail(email)) {
            setErrorCode('EMAIL_INVALID');
            return;
        }
        try {
            await passwordReset(email);
            setUiStatus('emailSent');
        } catch (error) {
            setErrorCode(error.message ?? 'UNKNOWN_ERROR');
            console.error('Could not reset password', error);
        }
    }

    const EnterEmail = (
        <div>
            <h2>Find your Those Beyond account</h2>
            <p>Enter the email associated with your account to change your password.</p>
            <label htmlFor="arise-forgotten-email">Email</label>
            <Input
                className={styles.input}
                id="arise-forgotten-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                autoComplete="email"
            />
            <AuthButton disabled={!email} text="Continue" onClick={onSubmit} style={{ marginTop: 10 }} />
            <p className={styles.errorMessage}>{getPasswordResetErrorMessage(errorCode)}</p>
        </div>
    );

    const EmailSent = (
        <div>
            <h2>Email Sent</h2>
            <p>
                We sent an email to {email}. If this email is connected to a Those Beyond account, you'll be able to
                reset your password.
            </p>
            <br />
            <p>
                Didn't get the email? Try these tips from our{' '}
                <a target="_blank" rel="noopener noreferrer" href={helpUrl}>
                    Help Centre
                </a>
                .
            </p>
            <br />
            <AuthButton text="Back to Login" onClick={() => navigateToScreen('login')} />
            {/* Try again button here? */}
        </div>
    );
    return uiStatus === 'enterEmail' ? EnterEmail : EmailSent;
}

function getPasswordResetErrorMessage(errorCode: ForgottenPasswordErrorCode): string {
    if (!errorCode) return '';
    switch (errorCode) {
        case 'EMAIL_INVALID':
            return EMAIL_RULE_STRING;
        default:
            return `Could not reset password, please contact ${SUPPORT_EMAIL}`;
    }
}
