import { useState, useEffect } from 'react';

import { useTheme } from '@/theme';
import Input from '@/components/Forms/Input';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { isValidEmail } from '@/utils/validation';
import { PASSWORD_RULE_STRING, isValidPassword, EMAIL_RULE_STRING } from '@/utils/validation';
import { login, register } from '@/state/features/auth';
import { loadCurrentUserProfile } from '@/state/features/profile';
import { RegistrationErrorCode } from '@/models/auth';

import { AuthButton } from '../../components/LoginButton';
import { ButtonLink } from '../../components/ButtonLink';
import { Turnstile } from '../../components/Turnstile';
import { AriseAuthScreenProps } from '../..';
import * as styles from '../../styles.module.scss';

export default function EmailSignup({ navigateToScreen, closeModal }: AriseAuthScreenProps) {
    const theme = useTheme();
    const auth = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const authConfig = theme.metadata?.authConfig;

    const termsUrl = theme.customJSON?.auth?.links?.terms;
    const privacyUrl = theme.customJSON?.auth?.links?.privacy;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [agreementChecked, setAgreementChecked] = useState(false);
    const [turnstileToken, setTurnstileToken] = useState('');
    const [errorCode, setErrorCode] = useState<RegistrationErrorCode>();

    function onSubmit() {
        if (auth.isRegistering) return;

        if (!isValidEmail(email)) {
            setErrorCode('EMAIL_INVALID');
            return;
        }

        if (!isValidPassword(password)) {
            setErrorCode('PASSWORD_TOO_SHORT');
            return;
        }

        if (!email || !password || (authConfig?.enableTurnstile && !turnstileToken)) return;
        dispatch(register([email, password, turnstileToken]));
    }

    useEffect(() => {
        // Registered successfully, now log user in
        if (auth.isRegistrationSuccessful) {
            dispatch(login([email, password]));
        }
    }, [auth.isRegistrationSuccessful]);

    useEffect(() => {
        // Failed to register, show error
        if (auth.registerError) {
            window['turnstile']?.reset?.();
            setErrorCode(auth.registerError);
        }
    }, [auth.registerError]);

    useEffect(() => {
        // User is registered and logged in, load their profile and close modal
        if (auth.user?.email === email) {
            dispatch(loadCurrentUserProfile([auth.user.id]));
            closeModal();
        }
    }, [auth.loggedIn, auth.user?.email]);

    return (
        <div>
            <h2>Enter your details</h2>
            <label htmlFor="arise-registration-email">Email</label>
            <Input
                className={styles.input}
                id="arise-registration-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                autoComplete="email"
            />
            <label htmlFor="arise-registration-password">Password</label>
            <Input
                className={styles.input}
                id="arise-registration-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                type="password"
                autoComplete="new-password"
            />
            <div className={styles.checkboxContainer}>
                <input
                    type="checkbox"
                    id="arise-registration-terms-check"
                    checked={agreementChecked}
                    onChange={(e) => setAgreementChecked(e.target.checked)}
                ></input>
                <label htmlFor="arise-registration-terms-check">
                    I have read and agree to{' '}
                    <a target="_blank" rel="noopener noreferrer" href={termsUrl}>
                        Those Beyond’s Terms of Service
                    </a>{' '}
                    and{' '}
                    <a target="_blank" rel="noopener noreferrer" href={privacyUrl}>
                        Privacy Policy
                    </a>
                    .
                </label>
            </div>
            {authConfig?.enableTurnstile && (
                <Turnstile
                    className={styles.turnStyle}
                    onToken={(token: string) => setTurnstileToken(token)}
                    onError={() => setErrorCode('VERIFICATION_FAILED')}
                />
            )}
            <AuthButton
                disabled={!(email && password && agreementChecked && (!authConfig?.enableTurnstile || turnstileToken))}
                text="Continue"
                onClick={onSubmit}
            />
            <span className={styles.bottomText}>
                Have an account?{' '}
                <ButtonLink isHighlighted onClick={() => navigateToScreen('login')}>
                    Log in
                </ButtonLink>
            </span>
            <p className={styles.errorMessage}>{getRegistrationErrorMessage(errorCode)}</p>
        </div>
    );
}

function getRegistrationErrorMessage(errorCode: RegistrationErrorCode): string {
    if (!errorCode) return '';
    switch (errorCode) {
        case 'EMAIL_TAKEN':
            return 'This email is already in use.';
        case 'EMAIL_INVALID':
            return EMAIL_RULE_STRING;
        case 'PASSWORD_TOO_SHORT':
            return PASSWORD_RULE_STRING;
        case 'MISSING_FIELDS':
            return 'Please fill in all required fields.';
        case 'VERIFICATION_FAILED':
            return 'Verification failed. Please try again or refresh the page.';
        default:
            return 'An unknown error occurred. Please try again.';
    }
}
