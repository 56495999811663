import * as styles from './styles.module.scss';
import classNames from 'classnames';
import { msTimeDifferenceFromNow } from '@/utils/time';
import Icon from '@/components/Icon';
import IconButton from '@/components/IconButton';
// TODO - timer?
// TODO - rollover for locked state?

interface SceneSelectorTileProps {
    onClick: () => void;
    imageUrl?: string;
    lockedImageUrl?: string;
    imageMaskUrl?: string;
    description?: string;
    lockedText?: string;
    lockedSubtext?: string;
    tabbable?: boolean;
    isInCenter?: boolean;
    isOpen?: boolean;
}

export default function SceneSelectorTile({
    onClick,
    imageUrl,
    imageMaskUrl,
    lockedImageUrl,
    description,
    lockedText,
    lockedSubtext,
    tabbable = true,
    isInCenter = true,
    isOpen,
}: SceneSelectorTileProps) {
    function handleKeyDown(e: React.KeyboardEvent<HTMLAnchorElement>) {
        if (e.key === 'Enter' && onClick) onClick();
    }

    const renderLockedText = () => (
        <div className={styles.SceneSelectorTile__textContainer}>
            <p>{lockedText}</p>
            <p className={styles.SceneSelectorTile__lockedSubtext}>{lockedSubtext}</p>
        </div>
    );

    const renderUnlockedText = () => (
        <div className={styles.SceneSelectorTile__textContainer}>
            <p>{description}</p>
        </div>
    );

    return (
        <div
            className={classNames(styles.SceneSelectorTile, {
                [styles['SceneSelectorTile--locked']]: !isOpen,
                [styles['SceneSelectorTile--inCenter']]: isInCenter,
            })}
        >
            <a
                className={styles.SceneSelectorTile__anchor}
                onClick={onClick}
                tabIndex={tabbable ? 0 : -1}
                onKeyDown={handleKeyDown}
            >
                <div
                    className={styles.SceneSelectorTile__customBorder}
                    style={imageMaskUrl ? { maskImage: `url(${imageMaskUrl})` } : {}}
                ></div>
                <div
                    className={styles.SceneSelectorTile__imageContainer}
                    style={imageMaskUrl ? { maskImage: `url(${imageMaskUrl})` } : {}}
                >
                    <div
                        className={styles.SceneSelectorTile__image}
                        style={{ backgroundImage: isOpen ? `url(${imageUrl})` : `url(${lockedImageUrl})` }}
                    ></div>
                </div>
                {isOpen ? null : (
                    <div className={styles.SceneSelectorTile__lockedIcon}>
                        <Icon type="locked" size={72} />
                    </div>
                )}
                <IconButton
                    className={styles.SceneSelectorTile__playButton}
                    iconType={isOpen ? 'play' : 'padlock'}
                    ariaLabel=""
                    buttonStyle="diamond"
                    disabled={!isOpen}
                    aria-hidden={true}
                    tabIndex={-1}
                />
            </a>
            {isOpen ? renderUnlockedText() : renderLockedText()}
        </div>
    );
}
