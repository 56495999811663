import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// import { ExperienceEvents, HostEvents } from 'arise-bridge';
import { useAppDispatch } from '@/state/store';
import {
    selectInventoryItem,
    setArisewareIsSnackbarVisible,
    setCurrentlyViewingMedia,
    setCurrentlyViewingPrize,
    setIsInventoryOpen,
    setIsSupplyRunLeaderboardOpen,
} from '@/state/features/app';
import { useAriseBridge } from '@/arise/bridge/useAriseBridge';
import { AriseBridgeHost } from '@/arise/bridge/AriseBridgeHost';
import { failedGame, submitFeedUpdate, queueAchievement } from '@/state/features/app';
import LoadingScreen from '../LoadingScreen';
import { sendEvent } from '@/utils/analytics';
import { tunaLab } from '@/arise/api';
import useTitanEvents from '@/components/IframeContainer/useTitanEvents';
import useHyperionEvents from '@/components/IframeContainer/useHyperionEvents';
import { homePath } from '@/theme/vars';
import { useNavigateToSignup } from '@/hooks/auth';

interface IframeContainerProps {
    src?: string;
    bridgeRef?: React.MutableRefObject<AriseBridgeHost>;
    arisewareProjectID?: string;
    onReady?: () => void;
    style?: React.CSSProperties;
}

export default function IframeContainer({ src, bridgeRef, arisewareProjectID, onReady, style }: IframeContainerProps) {
    const navigate = useNavigate();
    const { locationId } = useParams();
    const [iframeReady, setIframeReady] = useState(false);
    const iframeRef = useRef<HTMLIFrameElement>(null);

    let iframeUrl = src;

    const url = new URL(window.location.href);
    const iframeUrlParam = url.searchParams.get('iframeUrl');
    // If iframeUrl is passed as a query param, use that instead
    if (iframeUrlParam) {
        iframeUrl = iframeUrlParam;
    }

    const dispatch = useAppDispatch();

    const bridge = useAriseBridge(iframeRef, {
        arisewareProjectID,
    });
    // Expose bridge to parent
    if (bridgeRef) {
        bridgeRef.current = bridge;
    }

    const titanEvents = useTitanEvents(arisewareProjectID);
    const hyperionEvents = useHyperionEvents();
    const navigateToSignup = useNavigateToSignup();

    useEffect(() => {
        if (!bridge || !iframeUrl) return;

        function onReadyHandler(data: any) {
            setIframeReady(data.detail);
            if (data.detail === true) {
                onReady();
            }
        }

        function onFailed() {
            dispatch(failedGame({ arisewareProjectID }));
        }

        function onFeedUpdate(data: any) {
            dispatch(submitFeedUpdate(data.detail));
        }

        function onQueueAchievement(data: any) {
            dispatch(queueAchievement(data.detail));
        }

        function onSetSnackbarVisibility(data: any) {
            dispatch(setArisewareIsSnackbarVisible(data.detail));
        }

        function onSetInventoryIsOpen(data: any) {
            dispatch(setIsInventoryOpen(data.detail));
        }

        function onShowSupplyRunLeaderboard(data: any) {
            // True or false
            dispatch(setIsSupplyRunLeaderboardOpen(data.detail));
        }

        function onSelectInventoryItem() {
            dispatch(selectInventoryItem());
        }

        function onTrack(data: any) {
            const { event, data: eventData } = data.detail;
            sendEvent(event, eventData);
        }

        // Tunalab is our internal event data gathering system
        function onTunaLab(data: any) {
            tunaLab(locationId, data.detail);
        }

        function onGotoHome() {
            navigate(homePath);
        }

        function onGotoSignup() {
            navigateToSignup();
        }

        function onViewMedia(data: any) {
            dispatch(setCurrentlyViewingMedia(data.detail));
        }

        function onViewPrize(data: any) {
            dispatch(setCurrentlyViewingPrize(data.detail));
        }

        function onCopyText(data: any) {
            try {
                navigator.clipboard.writeText(data.detail);
            } catch (error) {
                console.error('Bridge event copy-text could not write to clipboard: ', error);
            }
        }

        bridge.on('ready', onReadyHandler);
        bridge.on('failed', onFailed);
        bridge.on('feed-update', onFeedUpdate);
        bridge.on('queue-achievement', onQueueAchievement);
        bridge.on('set-snackbar-visibility', onSetSnackbarVisibility);
        bridge.on('set-inventory-is-open', onSetInventoryIsOpen);
        bridge.on('show-supply-run-leaderboard', onShowSupplyRunLeaderboard);
        bridge.on('select-inventory-item', onSelectInventoryItem);
        bridge.on('track', onTrack);
        bridge.on('tunaLab', onTunaLab);
        bridge.on('goto_home', onGotoHome);
        bridge.on('goto_signup', onGotoSignup);
        bridge.on('view-media', onViewMedia);
        bridge.on('view-prize', onViewPrize);
        bridge.on('copy-text', onCopyText);

        if (process.env.PUBLIC_INSTANCE === 'titan') {
            bridge.on('share', titanEvents.onShare);
            bridge.on('goto_camp', titanEvents.onGotoCamp);
            bridge.on('goto', titanEvents.onGoto);
            bridge.on('submitresult', titanEvents.onSubmitResult);
        }
        if (process.env.PUBLIC_INSTANCE === 'hyperion') {
            bridge.on('goto', hyperionEvents.onGoto);
        }

        return () => {
            dispatch(setArisewareIsSnackbarVisible(null));
            bridge.off('ready', onReady);
            bridge.off('failed', onFailed);
            bridge.off('feed-update', onFeedUpdate);
            bridge.off('queue-achievement', onQueueAchievement);
            bridge.off('set-snackbar-visibility', onSetSnackbarVisibility);
            bridge.off('set-inventory-is-open', onSetInventoryIsOpen);
            bridge.off('show-supply-run-leaderboard', onShowSupplyRunLeaderboard);
            bridge.off('select-inventory-item', onSelectInventoryItem);
            bridge.off('track', onTrack);
            bridge.off('tunaLab', onTunaLab);
            bridge.off('goto_home', onGotoHome);
            bridge.off('goto_signup', onGotoSignup);
            bridge.off('view-media', onViewMedia);
            bridge.off('view-prize', onViewPrize);
            bridge.off('copy-text', onCopyText);

            if (process.env.PUBLIC_INSTANCE === 'titan') {
                bridge.off('share', titanEvents.onShare);
                bridge.off('goto_camp', titanEvents.onGotoCamp);
                bridge.off('goto', titanEvents.onGoto);
                bridge.off('submitresult', titanEvents.onSubmitResult);
            }
            if (process.env.PUBLIC_INSTANCE === 'hyperion') {
                bridge.off('goto', hyperionEvents.onGoto);
            }
        };
    }, [bridge, iframeUrl]);

    return (
        <>
            <iframe
                allow="xr-spatial-tracking"
                ref={iframeRef}
                src={iframeUrl}
                style={{
                    width: '100%',
                    height: '100%',
                    opacity: iframeReady ? 1 : 0,
                    ...style,
                }}
            />
            <LoadingScreen show={!iframeReady} />
        </>
    );
}
