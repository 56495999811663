import classNames from 'classnames';

import { AuthProvider } from '@/models/instance';

import * as styles from './styles.module.scss';

const icons = {
    google: (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{ display: 'block' }}>
            <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
            ></path>
            <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
            ></path>
            <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
            ></path>
            <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
            ></path>
            <path fill="none" d="M0 0h48v48H0z"></path>
        </svg>
    ),
    apple: (
        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.84024 4.84615C9.7832 4.84615 10.9652 4.20534 11.6691 3.35092C12.3066 2.57661 12.7715 1.49523 12.7715 0.413859C12.7715 0.267006 12.7582 0.120153 12.7316 0C11.6824 0.0400509 10.4207 0.707566 9.66367 1.60203C9.06602 2.2829 8.52148 3.35092 8.52148 4.44565C8.52148 4.60585 8.54805 4.76605 8.56133 4.81945C8.62774 4.8328 8.73398 4.84615 8.84024 4.84615ZM5.51992 21C6.8082 21 7.3793 20.1322 8.98633 20.1322C10.6199 20.1322 10.9785 20.9733 12.4129 20.9733C13.8207 20.9733 14.7637 19.665 15.6535 18.3833C16.6496 16.9148 17.0613 15.473 17.0879 15.4062C16.9949 15.3795 14.2988 14.2715 14.2988 11.1608C14.2988 8.46408 16.4238 7.24921 16.5434 7.15575C15.1355 5.12651 12.9973 5.07311 12.4129 5.07311C10.8324 5.07311 9.54414 6.03433 8.73398 6.03433C7.85742 6.03433 6.70195 5.12651 5.33398 5.12651C2.73086 5.12651 0.0878906 7.28926 0.0878906 11.3744C0.0878906 13.911 1.0707 16.5944 2.2793 18.3299C3.31523 19.7985 4.21836 21 5.51992 21Z"
                fill="black"
            />
        </svg>
    ),
};

function ExternalLoginButton({ provider, text }: { provider: 'google' | 'apple'; text: string }) {
    const PROVIDER_URLS = {
        google: process.env.PUBLIC_ARISEENGINE_HOST + '/auth/oauth/google/begin?state=',
        apple: process.env.PUBLIC_ARISEENGINE_HOST + '/auth/oauth/apple/begin?state=',
    };

    function getUrl() {
        if (!crypto?.randomUUID) return;
        const key = crypto.randomUUID();
        const state = encodeURIComponent(btoa(window.location.origin + '?key=' + key));
        return PROVIDER_URLS[provider] + state;
    }

    const onClick = () => {
        window.location.href = getUrl();
    };

    if (!PROVIDER_URLS[provider]) {
        console.warn(`oAuth provider not supported: ${provider}`);
        return null;
    }

    return (
        <button className={styles['gsi-material-button']} onClick={onClick} disabled={!crypto?.randomUUID}>
            <div className={styles['gsi-material-button-state']}></div>
            <div className={styles['gsi-material-button-content-wrapper']}>
                <div className={styles['gsi-material-button-icon']}>{icons[provider]}</div>
                <span className={styles['gsi-material-button-contents']}>{text}</span>
            </div>
        </button>
    );
}

export function ExternalLoginButtons({ authProviders }: { authProviders: AuthProvider[] }) {
    if (!authProviders || !authProviders.length) return null;

    const getButton = (provider: AuthProvider, key: string | number) => {
        switch (provider) {
            case 'google':
                return <ExternalLoginButton provider="google" text="Continue with Google" key={key} />;
            case 'apple':
                return <ExternalLoginButton provider="apple" text="Continue with Apple" key={key} />;
            default:
                console.warn('Unsupported oAuth:', provider);
                break;
        }
    };
    return (
        <div className={styles.externalLoginButtons}>
            {authProviders.map((provider, index) => getButton(provider, index))}
        </div>
    );
}

interface AuthButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    onClick: () => void;
    isHighlighted?: boolean;
}

export function AuthButton({ text, onClick, isHighlighted = true, ...props }: AuthButtonProps) {
    return (
        <button
            {...props}
            className={classNames(styles.AuthButton, { [styles.isHighlighted]: isHighlighted })}
            onClick={onClick}
        >
            {text}
        </button>
    );
}
