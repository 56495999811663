@import '../../style/globals.scss';

// Also referenced in AuthDrawer.tsx
$drawerImageWidth: 989px;
$drawerImageHeight: 1117px;
$defaultColour: var(--token-accent-theme-1-interface-mono-interface-background);

.AuthDrawer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    &__drawer {
        position: absolute;
        left: calc(-1 * (100% + $drawerImageWidth));
        width: calc(100% + $drawerImageWidth);
        height: 100%;
        margin-left: 864px;

        @media (max-width: $bp-s) {
            margin-left: calc(100% + 200px);
        }

        &Left {
            display: inline-block;
            width: calc(100% - $drawerImageWidth);
            height: 100%;
            overflow: hidden;
        }

        &Right {
            position: relative;
            display: inline-block;
            overflow: hidden;
            width: $drawerImageWidth;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position-x: right;
        }
    }

    // Default style if no image passed in
    &--noImage &__drawer {
        bottom: 0;
        height: 100%;
        min-height: $drawerImageHeight;
        clip-path: polygon(0% 0%, calc(100% - 400px) 0%, 100% 100%, 0% 100%);
        backdrop-filter: blur(10px);

        &Left,
        &Right {
            background-color: $defaultColour !important;
        }
    }
}
