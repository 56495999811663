import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetAppState } from './app';
import { LoginErrorCode, RegistrationErrorCode, User } from '@/models/auth';
import * as arise from '@/arise/api';
import { resetProfileState } from '@/state/features/profile';
import { setUserId } from '@/utils/analytics/amplitute';
import { sendEvent } from '@/utils/analytics';

interface AuthState {
    user?: User;
    loggedIn: boolean;
    isLoggingIn: boolean;
    loginError?: LoginErrorCode;
    intialAuthCheckComplete: boolean;
    isInitialAuthCheckInProgress: boolean;
    isRegistering: boolean;
    isRegistrationSuccessful: boolean;
    registerError?: RegistrationErrorCode;
    hasLoggedOut?: boolean;
}

const initialState: AuthState = {
    loggedIn: false,
    isLoggingIn: false,
    intialAuthCheckComplete: false,
    isInitialAuthCheckInProgress: false,
    isRegistering: false,
    isRegistrationSuccessful: false,
};

export const initialAuthState = initialState;

export const login = createAsyncThunk<void, [email: string, password: string], { rejectValue: string }>(
    'auth/login',
    async ([email, password], thunkApi) => {
        try {
            await arise.login(email, password);
            thunkApi.dispatch(postLogin());
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.message || 'UNKNOWN_ERROR');
        }
    },
);

export const postLogin = createAsyncThunk<User, undefined>('auth/postLogin', async (_, thunkApi) => {
    const user = await arise.me();
    setUserId(user.id);
    sendEvent('login');
    return user;
});

export const loginAsGuest = createAsyncThunk<void, undefined>('auth/loginGuest', async (_, thunkApi) => {
    await arise.loginAsGuest();
    thunkApi.dispatch(postLogin());
});

export const logout = createAsyncThunk<void, undefined>('auth/logout', async (_, t) => {
    await arise.logout();
    t.dispatch(resetAppState());
    t.dispatch(resetProfileState());
});

export const register = createAsyncThunk<
    boolean,
    [email: string, password: string, turnstileToken: string],
    { rejectValue: string }
>('auth/register', async ([email, password, turnstileToken], thunkApi) => {
    try {
        const result = await arise.register(email, password, turnstileToken);
        return result.success;
    } catch (error: any) {
        return thunkApi.rejectWithValue(error.message || 'UNKNOWN_ERROR');
    }
});

export const initialAuthCheck = createAsyncThunk<User | null>('auth/initialAuthCheck', async (_, thunkApi) => {
    if (!arise.getAuthToken()) {
        return null;
    }

    const user = await arise.me();

    if (user) {
        setUserId(user.id);
        sendEvent('reopened_app');
        return user;
    }

    return null;
});

const handlePendingLogin = (state) => {
    state.isLoggingIn = true;
    state.loginError = undefined;
};
const handleRejectedLogin = (state, errorMessage) => {
    // state.loggedIn = false;
    state.isLoggingIn = false;
    state.loginError = errorMessage;
};

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateUser(state, action) {
            state.user = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, handlePendingLogin);
        builder.addCase(login.rejected, (state, action) => {
            handleRejectedLogin(state, action.payload);
        });

        builder.addCase(loginAsGuest.pending, handlePendingLogin);
        builder.addCase(loginAsGuest.rejected, (state, action) => {
            handleRejectedLogin(state, action.payload);
        });

        builder.addCase(postLogin.fulfilled, (state, action) => {
            state.user = action.payload;
            state.loggedIn = true;
            state.isLoggingIn = false;
            state.loginError = undefined;
        });

        builder.addCase(postLogin.rejected, (state, action) => {
            handleRejectedLogin(state, action.payload);
        });

        builder.addCase(register.pending, (state) => {
            state.isRegistering = true;
            state.registerError = undefined;
            state.isRegistrationSuccessful = false;
        });

        builder.addCase(register.fulfilled, (state) => {
            state.isRegistering = false;
            state.isRegistrationSuccessful = true;
        });

        builder.addCase(register.rejected, (state, action) => {
            state.isRegistering = false;
            state.registerError = action.payload as RegistrationErrorCode;
            state.isRegistrationSuccessful = false;
        });

        builder.addCase(initialAuthCheck.pending, (state) => {
            state.isInitialAuthCheckInProgress = true;
        });

        builder.addCase(initialAuthCheck.fulfilled, (state, action) => {
            if (action.payload) {
                state.user = action.payload;
                state.loggedIn = !!action.payload;
            }
            state.isInitialAuthCheckInProgress = false;
            state.intialAuthCheckComplete = true;
        });

        builder.addCase(initialAuthCheck.rejected, (state, action) => {
            state.isInitialAuthCheckInProgress = false;
            state.intialAuthCheckComplete = true;
        });

        builder.addCase(logout.pending, (state) => {
            state.loggedIn = false;
            state.intialAuthCheckComplete = true;
            state.user = undefined;
            state.isLoggingIn = false;
            state.hasLoggedOut = true;
        });

        builder.addCase(logout.rejected, (state, action) => {
            console.log(action.error);
        });

        builder.addCase(logout.fulfilled, (state) => {
            // ?
        });
    },
});

export const { updateUser } = auth.actions;

export const { actions: authActions, reducer: authReducer } = auth;

export default authReducer;
