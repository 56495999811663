import classNames from 'classnames';

import { useTheme } from '@/theme';
import { pathToURL } from '@/utils/urls';
import { sendEvent } from '@/utils/analytics';
import { ExternalShopItem } from '@/models/shop';
import { LinkButton } from '@/components/Forms/Button';
import CardDescription from '@/components/CardDescription';

import * as styles from './styles.module.scss';

interface ShopExternalItemProps {
    classname?: string;
    item: ExternalShopItem;
}

export const ShopExternalItem = ({ classname, item }: ShopExternalItemProps) => {
    const theme = useTheme();
    if (!item) return;

    const buttonText = theme.customJSON.shop?.externalShopItemButtonText ?? 'Shop Now';

    return (
        <div
            className={classNames(styles.ShopExternalItem, classname, {
                [styles.noBackgroundImage]: !theme.customJSON?.cardBackgroundImageUrl,
            })}
            style={{ backgroundImage: `url("${theme.customJSON?.cardBackgroundImageUrl}")` }}
        >
            <CardDescription
                classname={styles.label}
                title={item.title}
                subtitle={item.sub_title}
                maskImage={theme.customJSON?.cardTextBackgroundImageUrl}
                keepFontSize
            />
            <span className={styles.price}>{item.price}</span>
            <img className={styles.image} src={pathToURL(item.imagePath) + '?w=500'} alt={item.title} />
            <div className={styles.interactionContainer}>
                <LinkButton
                    className={styles.button}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                        sendEvent('external_shop_link_open', {
                            id: item.id,
                            title: item.title,
                            price: item.price,
                            url: item.link,
                        })
                    }
                >
                    {buttonText}
                </LinkButton>
            </div>
        </div>
    );
};

export default ShopExternalItem;
